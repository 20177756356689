<template>
  <div>
    <EstoreCard />

    <!-- 検索欄 -->
    <div class="input-area">
      <v-toolbar
        dark
        color="gray darken-3"
        class="mb-1"
      >
        <v-text-field
          v-model="inputWordHousinger"
          clearable
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('message.search')"
          @input="onInputChangeHousinger"
        />
      </v-toolbar>
    </div>

    <!-- Housingerカードリスト -->
    <v-container>
      <v-row>
        <v-col
          v-for="(item, index) in itemsHousinger"
          :key="index"
          cols="6"
          sm="4"
          md="3"
          lg="2"
          xl="2"
        >
          <housinger-card
            :item="item"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- 無限スクロール -->
    <infinite-loading
      :identifier="infiniteIdHousinger"
      @infinite="infiniteHandlerHousinger"
    >
      <v-alert
        slot="no-results"
        class="mt-5 pt-5"
        dense
        text
        type="success"
      >
        {{ $t("message.no-results") }}
      </v-alert>
      <v-alert
        slot="no-more"
        class="mt-5 pt-5"
        dense
        text
        type="success"
      >
        {{ $t("message.no-more") }}
      </v-alert>
      <v-alert
        slot="error"
        class="mt-5 pt-5"
        dense
        outlined
        type="error"
      >
        {{ $t("message.infinite-error") }}
      </v-alert>
    </infinite-loading>
  </div>
</template>

<script>
  import axios from 'axios'
  import InfiniteLoading from 'vue-infinite-loading'
  import HousingerCard from '../components/HousingerCard.vue'

  export default {
    metaInfo: {
      title: 'HousingDesigners',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        {
          name: 'keywords',
          content:
            'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden',
        },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content:
            'FFXIV Housing EdenはFFXIV(ファイナルファンタジー14)でのハウジング情報を配信するサイトです。',
        },
        {
          property: 'og:image',
          content: 'https://ff14eden.work/twitter_card.jpg?3',
        },
      ],
    },

    components: {
      InfiniteLoading,
      HousingerCard,
    },
    data () {
      return {
        items: [],
        inputWordHousinger: '',
        listHousinger: [],
        itemsHousinger: [],
        searchedItemsHousinger: [],
        startHousinger: 0,
        perData: 9,
        getDataHousinger: 0,
        infiniteIdHousinger: 0,
      }
    },
    created: async function () {
      await axios
        .get(
          `../../../user/all_users_master.json?timestamp=${new Date().getTime()}`,
        )
        .then((res) => {
          // update_dateを基に降順に並び替え
          this.items = res.data.sort((a, b) => new Date(b.update_date) - new Date(a.update_date))
          this.itemsHousinger = [...this.items]
          // console.log(this.itemsHousinger)
        })
    },
    methods: {
      // 検索結果を初期化
      initializeSearchResults () {
        this.infiniteIdHousinger += 1
        this.searchedItemsHousinger = [...this.items]
        this.itemsHousinger = []
        this.startHousinger = 0
      },
      // データの読み込み
      dataLoadHousinger () {
        this.itemsHousinger.push(
          ...this.searchedItemsHousinger.slice(
            this.startHousinger,
            this.startHousinger + this.perData,
          ),
        )
        this.startHousinger = this.startHousinger + this.perData
      },
      // 無限スクロールのハンドラー
      async infiniteHandlerHousinger ($state) {
        // Housingerデータ処理
        if (this.getDataHousinger === 0) {
          this.initializeSearchResults()

          this.getDataHousinger = 1
        }
        this.dataLoadHousinger()
        if (this.searchedItemsHousinger.length <= this.startHousinger) {
          $state.complete()
        } else {
          $state.loaded()
        }
      },

      // 入力欄の変更時の処理
      onInputChangeHousinger () {
        this.infiniteIdHousinger += 1
        this.itemsHousinger = []
        this.$nextTick(() => {
          this.initializeSearchResults()
          this.searchedItemsHousinger = this.searchedItemsHousinger.filter(item =>
            item.user_name && typeof item.user_name === 'string' && item.user_name.toLowerCase().includes(this.inputWordHousinger.toLowerCase()),
          )
          this.dataLoadHousinger()
        })
      },
    },
  }
</script>
